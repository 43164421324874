#process, #why{
    padding-top: 355px;
    margin-top:-305px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        padding-top: 100px;
        margin-top: 0;
    }
    
    position: relative;
    z-index: 1;
    padding-bottom:190px;
    h2  {
        margin-top: 35px;
        font-weight: 700;
        @media(max-width: 767px){
            font-size: 25px;
        }
        
    }
    h3  {
        font-weight: 700;
        
    }

    
    .text{
        display: block;
        width: 55%;
        margin: 0 auto;
        font-size: 20px; 
        font-weight: 100;
        @media(max-width: 991px){
            width: 80%;
        }
        @media(max-width: 767px){
            padding: 0 20px;
            width: 100%;
            font-size: 18px;
        }
        @media(max-width: 575px){
            padding: 0 0;
            width: 100%;
            font-size: 17px;
            font-weight: 300;
        }
        
    }
    background-color: #f2f3f3;
    background-image: url(../../img/binary.png);
    background-attachment: fixed;
    position:relative;
    z-index:1;
    color:$brown;
    .each   {
        transition: .3s all cubic-bezier(.19,1,.22,1);
        
        max-width: 20%;
        flex-basis: 20%;
        text-align: center;
        display: inline-block;
        margin-top: 40px;
        flex-grow: 1;
        @media(max-width: 991px){
            max-width: 33.3333%;
            flex-basis: 33.3333%;
        }
        @media(max-width: 767px){
            max-width: 50%;
            flex-basis: 50%;
        }
        @media(max-width: 400px){
            max-width: 100%;
            flex-basis: 100%;
        }
        img {
            display:block;
            margin:0 auto;
            max-width:80%;
        }
        .title{
            font-size: 20px;
            font-weight: 700;
            margin: 20px auto 10px;
            @media(max-width: 991px){
                margin: 15px auto 10px;
            }
            @media(max-width: 767px){
                margin: 10px auto 5px;
                font-size: 18px;
            }
        }

        p{
            font-size: 14px;
            font-weight: 300;
            max-width: 80%;
            text-align: center;
            margin: 10px auto;
            @media(max-width: 767px){
                margin-top: 5px;
            }
        }

    }

    .each2   {
        max-width: 33.3333%;
        flex-basis: 33.3333%;
        text-align: center;
        display: inline-block;
        margin-top: 40px;
        img {display:block; margin:0 auto; max-width:80%;}
        @media(max-width: 991px){
            max-width: 50%;
            flex-basis: 50%;
        }
        @media(max-width: 575px){
            max-width: 100%;
            flex-basis: 100%;
        }
        p{
            font-size: 14px;
            font-weight: 300;
            max-width: 80%;
            text-align: center;
            margin: 10px auto 10px;
        }
        .title{

            font-size: 20px;
            margin: 10px auto 10px;
            font-weight: 700;
            
            @media(max-width: 767px){
                margin: 5px auto 5px;
                font-size: 18px;
            }
        }
        .button{
            margin-top: 10px;
            @media(max-width: 767px){
                 margin-top: 0;
            }
        }
    }
    &.tiles{
        background-image:  url(../../img/tiles.png);
        background-color: #f2f3f3;
        background-size: auto;
        background-position: 50% 50%;
        padding-bottom: 80px;
        @media(max-width: 575px){
            
            padding-bottom: 30px;
        }
    }

    &.no-margins{
        margin: 0;
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

#process{
    @media(max-width: 575px){
        padding-top: 60px!important;
        margin-top:-40px!important;
        padding-bottom: 25vw;
    }
}

#why{
    padding-top: 50px;
    margin-top:0;
    padding-bottom: 90px;
    background: #f2f3f3 url(../../img/tiles.png);
    &.clip-path{
        //clip-path: polygon(0px 100%,100% 100%,100% 10%,95% 0%,90% 10%,85% 0%,80% 10%,75% 0%,70% 10%,65% 0%,60% 10%,55% 0%,50% 10%,45% 0%,40% 10%,35% 0%,30% 10%,25% 0%,20% 10%,15% 0%,10% 10%,5% 0%,0% 10%);
    }
}


#responsive{
    margin-top: -140px;
    padding-bottom: 50px;
    position: relative;
    z-index: 10;
    text-align: center;
    
    h2  {
        margin-top: 35px; color: #fff;
        @media(max-width: 1199px){
                font-size: 34px;
            }
            @media(max-width: 991px){
                font-size: 30px;
            }
            @media(max-width: 767px){
                font-size: 25px;
                margin-top: 15px;
            }
            @media(max-width: 575px){
                font-size: 22px;
            }
    }

    .text{
        display: block;
        width: 70%;
        margin: 0 auto;
        font-size: 20px; 
        font-weight: 100;
        text-align: center;
        color: #fff;
        @media(max-width: 991px){
            font-size: 18px; 
        }
        @media(max-width: 767px){
            font-size: 18px; 
            width: 100%;
        }
        @media(max-width: 575px){
           font-weight: 300;
           font-size: 17px; 
        }
        
        
       
    }
    @media(max-width: 767px){
        padding-bottom: 30px;
    }
    @media(max-width: 575px){
        padding-bottom: 20px;
        margin-top: -21vw;
    }
}