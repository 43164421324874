body.ie, body.edge{
    .clip-svg{
            display: none;
    }
    #process, #why{
        padding-top: 100px;
        margin-top: 0;
    }

    .home-slider{
        @media (max-width: 575px){
            margin-bottom: -20px;
        }
    }

    .grid-wrapper {
        display: flex;
        align-items: stretch;
        justify-content: center;
        height: auto!important;
        overflow: initial;
        width: 100%;
        margin-bottom: 0;
        margin-top: 50px;

        .grid-item {
            -webkit-clip-path: none;
            clip-path: none;
            clip-path: none;
            flex-basis: 20%;
            max-width: 20%;
            position: relative;
            left: auto!important;
            right: auto!important;
            bottom: auto!important;
            top: auto!important;
            width: auto!important;
            height: auto!important;
            .diamond__content {
                width: auto!important;
                height: auto!important;
            }
            @media (max-width: 1400px) {
                flex-basis: 33.3333%;
                max-width: 33.3333%;
            }
            @media (max-width: 1000px){
                flex-basis: 50%;
                max-width: 50%;
                flex-grow: 1;
            }
            @media (max-width: 575px){
                flex-basis: 100%;
                max-width: 100%;
                flex-grow: 1;
            }
        }
        
    }

    .layout-1 {
        .diamond-extra-left, .diamond-extra-right {
            display: none;
        }
        .diamond-large {
            .diamond__content {
                .inner{
                        
                    h4{
                        font-size: 30px;
                        @media (max-width: 1400px){
                            font-size: 25px;
                        }
                    }
                    P{
                        
                        font-size: 18px;
                       
                        @media (max-width: 1400px) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}
