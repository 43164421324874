#mobile-menu {
    position: fixed;
    height: 100%;
    width: 250px;
    top: 0;
    left: 0;

    color: #fff;
    padding: 0 30px;
    z-index: 2000;
    height: 100%;
    background: $pink;
    transform: translateX(-100%);

    transition: all 0.3s ease;
    overflow: auto;
    box-shadow: 3px 0 18px rgba(0, 0, 0, 0.3);

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            transition: opacity .5s ease, transform .5s ease;
            transform: translateX(-50px);
            opacity: 0;
            margin-bottom: 15px;
            position: relative;

            &:nth-child(1) {
                transition-delay: 0s;
            }

            &:nth-child(2) {
                transition-delay: 0s;
            }

            &:nth-child(3) {
                transition-delay: 0s;
            }

            &:nth-child(4) {
                transition-delay: 0s;
            }

            &:nth-child(5) {
                transition-delay: 0s;
            }

            &:nth-child(6) {
                transition-delay: 0s;
            }

            a {
                color: #fff;
                font-weight: 900;
                display: block;
                text-align: left;
                font-size: 20px;
                margin-bottom: 10px;
                transition: 0s all;

                &:hover,
                &:focus,
                &.active {

                    color: $nav-bg;

                }

                span {
                    color: #000;
                }
            }
        }
    }

    .logo {

        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100px;
        }

        a {
            margin: 0;
            flex-basis: 100%;
            max-width: 100%;
        }

    }

}



#header {

    #logo {
        padding: 30px;

        img {
            width: 200px;

            @media(max-width: 991px) {
                width: 180px;
            }

            @media(max-width: 767px) {
                width: 160px;
            }

            @media(max-width: 575px) {
                width: 150px;
            }
        }

        @media(max-width: 767px) {
            padding: 15px;
        }
    }
    #menu-bar {
        height: 42px;
    }
    &.sticky {
        padding-bottom: 42px;
        #menu-bar {

            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1100;
        }
    }

    nav {
        background: $nav-bg;
        color: white;
        margin-bottom: 0px !important;

        a {

            text-decoration: none;
            padding: 10px 20px;
            display: inline-block;
            font-weight: 300;
            color: #ddd;
            background: $nav-bg;
            font-size: 17px;
            letter-spacing: 1px;
            &:hover,
            &:focus {
                color: #fff;
                background: $pink;
            }

            &.active{
                color: $pink;
            }


        }






    }
}

.top-header {
    text-align: center;
    padding: 50px 50px;

    @media(max-width: 767px) {
        padding: 30px 30px;
    }

    @media(max-width: 575px) {
        padding: 20px 0px;
    }

    h1 {
        font-size: 30px;
        font-weight: 100;
        color: $pink;
        font-size: 30px;

        @media(max-width: 1199px) {
            font-size: 30px;
        }

        @media(max-width: 991px) {
            font-size: 28px;
        }

        @media(max-width: 767px) {
            font-size: 25px;
        }

        @media(max-width: 575px) {
            font-size: 20px;
        }

        &.project-title { 
            font-size: 45px;
            color: #fff;
            font-weight: 700;

            @media(max-width: 1199px) {
                font-size: 33px;
            }

            @media(max-width: 991px) {
                font-size: 30px;
            }

            @media(max-width: 767px) {
                font-size: 28px;
            }

        }
    }

    h2 {
        font-size: 46px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 25px;

        @media(max-width: 1199px) {
            font-size: 40px;
        }

        @media(max-width: 991px) {
            font-size: 35px;
        }

        @media(max-width: 767px) {
            font-size: 30px;
            margin-bottom: 15px;
        }

        @media(max-width: 575px) {
            font-size: 25px;
        }
    }

    .text {
        font-size: 20px;
        line-height: 1.3;
        font-weight: 300;
        color: #fff;

        @media(max-width: 1199px) {
            font-size: 19px;
        }

        @media(max-width: 991px) {
            font-size: 18px;
        }

        @media(max-width: 767px) {
            font-size: 17px;
            margin-top: 10px;
        }

        @media(max-width: 575px) {
            font-size: 16px;
        }
    }

}