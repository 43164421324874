#footer{
    #testimonials{
        background: #22bcc9;
        
        color: #fff;
        padding: 250px 30% 30px;
        margin-top: -150px;
        @media(max-width: 1199px){
            padding: 250px 10% 30px;
            
        }
       @media(max-width: 991px){
            padding: 250px 100px 30px;
            margin-top: -200px;
        }
        @media(max-width: 767px){
            padding: 250px 20px 30px;
        }
        @media(max-width: 575px){
            padding: 230px 0 30px;
            margin-top: -180px;
        }
        
        .item{
            margin-bottom: 40px;
            text-align: center;
            @media(max-width: 767px){
                margin-bottom: 20px;
            }
        }

        .thumb{
            margin: 0 10px;
            margin-top: -5px;
            max-width: none;
            width: auto;
            display: inline-block;

        }

        p{
            font-size: 21px;
            text-align: center;
            font-weight: 100;
            line-height: 1.3;
            @media(max-width: 991px){
                font-size: 20px;
            }
            @media(max-width: 767px){
                font-size: 18px;
            }
            @media(max-width: 575px){
                font-size: 16px;
            }
        }

        h2{
            color:white;
            font-weight: 700;
            margin-bottom: 20px;
            text-align: center;
            @media(max-width: 1199px){
                font-size: 34px;
            }
            @media(max-width: 991px){
                font-size: 30px;
            }
            @media(max-width: 767px){
                font-size: 25px;
                margin-bottom:15px;
            }
            @media(max-width: 575px){
                font-size: 22px;
            }

        }
        h4{
            font-weight: 100;
            font-size: 18px;
            text-align: center;
            width: 80%;
            margin: 20px auto;
            line-height: 1.4;
            @media(max-width: 767px){
                width: 100%;
                padding: 0 10px;
                font-size: 17px;
            }
            @media(max-width: 767px){
                width: 100%;
                padding: 0 0;
                font-size: 16px;
            }
        }

        .name{
            font-weight: 400;
        }
        .owl-theme .owl-dots .owl-dot span{
            background: #1ED2E1;
        }




        .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
            background: #fff;
        }
    }

    #hello  {
        background:#f2f3f3;
        color:$brown;
        padding: 50px 30% 50px;
        @media(max-width: 1199px){
            padding: 50px 10% 30px;
            
        }
        @media(max-width: 991px){
            padding: 50px 100px 30px;
            
        }
        @media(max-width: 767px){
            padding: 30px 20px 30px;

        }
        @media(max-width: 767px){
            padding: 30px 0 30px;

        }
        h2{

            font-weight: 700;
            @media(max-width: 1199px){
                font-size: 34px;
            }
            @media(max-width: 991px){
                font-size: 30px;
            }
            @media(max-width: 767px){
                font-size: 25px;
            }
            @media(max-width: 575px){
                font-size: 22px;
            }
        }
        text-align:center;
        .contact  {

            margin: 20px;
            line-height: 52px; 
            color:$pink-selected; 
            vertical-align:top; 
            display:inline-block;
             @media(max-width: 991px){
                margin: 5px;
                line-height: 45px; 
             }

            a  {
                color:$pink;
                line-height: 52px; 
                color:$pink-selected; 
                vertical-align:top; 
                font-size: 22px;
                font-weight: 500;
                @media(max-width: 991px){
                    font-size: 20px;
                }
                @media(max-width: 767px){
                    font-size: 18px;
                }
                &:hover{
                    color: $pink-hover;
                    text-decoration: none;
                }
            }
            img{
                padding-right:10px;
                @media(max-width: 991px){
                    width: 45px;
                }
               
            }
        }
        .text{
            display: block;

            margin: 0 auto;
            margin-bottom: 10px;
            font-weight: 100;
            font-size: 20px;
            
            @media(max-width: 767px){
                font-size: 18px;
            }
            @media(max-width: 575px){
                font-size: 17px;
                font-weight: 300;
            }

        }
    }
    .footer{
        padding: 40px 0;
        text-align: center;
        background:$main-bg-image; background-attachment: fixed;

        img{
            width: 150px;
            margin-bottom: 20px;
        }

        .list-inline{
            padding: 10px 0;
            li.list-inline-item{
                @media(max-width: 575px){
                    display: block;
                    margin: 0!important;
                }
                a{
                    padding: 5px;
                    font-size: 14px;
                    color: #ddd;
                    display: block;
                    @media(max-width: 575px){
                        margin-bottom: 5px;
                        font-size: 16px;
                    }
                    &:hover, &:focus{
                        color: $pink;
                    }
                }
            }
        }

        .copyright{
            font-size: 11px;
            color: #ddd;
        }
    }

}