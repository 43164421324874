
#fabric-banner, #fabric-banner2 {
    position: relative;
    width: 100%;
    margin-bottom: 50px;
}

#fabric-banner2 {
    display: none;
    margin-bottom: -200px;
}

@media(max-width:767px) {
    #fabric-banner { display: none;}
    #fabric-banner2 { display: block; }
}

@media(max-width:575px) {
    #fabric-banner2 { display: none;}
}


#fabric-banner2:after {
    content: "";
    display: block;
    padding-bottom: 74%;
}
#fabric-banner:after {
    content: "";
    display: block;
    padding-bottom: 37.5%;
}
#fabric-banner > div,  #fabric-banner2 > div {
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
}
#fabric-banner canvas,  #fabric-banner2 canvas {
    height: 100% !important;
    width: 100% !important;
}
#fabric-banner canvas.hovering,  #fabric-banner2 canvas.hovering {
    cursor: pointer !important;
}

