.projects-page{


        .text{
            line-height: 1.8;
            font-weight: 300;
            color: #fff;

            h2{
                font-size: 35px;
                margin-top: 50px;
                margin-bottom: 20px;
            }
            h3{
                font-size: 30px;
                margin-top: 30px;
                margin-bottom: 10px;
            }
            h4{
                font-size: 25px;
                margin-top: 30px;
                margin-bottom: 10px;
            }

            ul{
                list-style-image: url(img/dot.svg);
                padding-left: 20px;
                li{
                    line-height: 1.8;
                }
            }
        }

        .loading{
            padding: 100px 0 200px;
            position: relative;

            background-image: $main-bg-image;
            background-size: auto;
            background-repeat: repeat;
            border-radius: 0px;
            background-attachment: fixed;
        }

        #loader {
            position: absolute;
            left: 50%;
            top: 50%;
            z-index: 1;
            width: 150px;
            height: 150px;
            margin: -75px 0 0 -75px;
            border: 16px solid #f3f3f3;
            border-radius: 50%;
            border-top: 5px solid #f3f3f3;
            border-bottom: 5px solid #f3f3f3;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            width: 120px;
            height: 120px;
            -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
            display: flex;
            align-items: center;
            justify-content: center;

          }

          @-webkit-keyframes spin {
            0% { -webkit-transform: rotate(0deg); }
            100% { -webkit-transform: rotate(360deg); }
          }

          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }



          .animate-bottom {
            position: relative;
            -webkit-animation-name: animatebottom;
            -webkit-animation-duration: 0.6s;
            animation-name: animatebottom;
            animation-duration: 0.6s
          }

          @-webkit-keyframes animatebottom {
            from { bottom:-50px; opacity:0 }
            to { bottom:0px; opacity:1 }
          }

          @keyframes animatebottom {
            from{ bottom:-50px; opacity:0 }
            to{ bottom:0; opacity:1 }
          }

    .tags{
        padding: 0px 10px 10px;
        margin-bottom: 40px;
        @media(max-width:575px) {
            padding:10px 10px 0;
        }

        .pmlink{
            margin: 5px;
            padding: 10px 20px;
            border-radius: 0px;
            letter-spacing: 2px;
            border: 1px solid $pink;
            background: transparent;
            font-weight: 300;
            color: #fff;
            font-size: 15px;
            &:focus{
                text-decoration: none;
                color: #fff;
            }

            &:hover{
                background: $pink;
            }

            &.active{
                background: darken($pink, 5%);
                border: 1px solid darken($pink, 5%);
            }
        }

        .title{
            display: inline-block;
            margin-right: 30px;

            @media(max-width:767px) {
                display: none;
            }

            i{
                color: $pink;
                font-size: 30px;
            }
            h5{
                font-size: 21px;
                font-weight: 500;
                display: inline-block;
                color: #fff;
                margin-left: 5px;
                vertical-align: top;
                margin-top: 0px;
                margin-bottom: 0px;

            }
        }
    }
    .projects{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 0 100px;
        max-width: 2000px;
        @media(max-width:1800px) {
            padding: 0 50px;
        }
        @media(max-width:767px) {
            padding: 0;
        }
        margin: 0 auto;
        margin-bottom: 250px;
        .element-item{
            flex-basis: 33.33333%;
            max-width: 33.33333%;

            @media(max-width:991px) {
                flex-basis: 50%;
                max-width: 50%;
            }
            @media(max-width:767px) {
                flex-basis: 100%;
                max-width: 100%;
            }
        }
    }
    .project{
        flex-grow: 1;
        flex-basis: 100%;
        position: relative;
        background-size: cover;
        background-position: 50% 50%;
        overflow: hidden;
        display:flex;
        align-items: center;
        justify-content: center;
        &:before{
            content: '';
            display: block;
            padding-bottom: 75%;

        }

        &:after{
            content: '';
            position: absolute;
            height: 15vw;
            @media(max-width: 575px){
                height: 20vw;
            }
            width: 100%;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(transparent, rgba(0,0,0,0.8));
            z-index: 5;
        }


        .bg{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            transition: transform 400ms cubic-bezier(0.7, 0, 0.3, 1) 0ms, opacity 800ms cubic-bezier(0.7, 0, 0.3, 1);
            z-index: 3;
        }


        .inner{
            flex-basis: 100%;
            max-width: 100%;
            position: relative;
            z-index: 10;
            position: absolute;
            bottom: 3vw;
            left: 3vw;
            right: 3vw;
            transform: matrix(1, 0, 0, 1, 0, 0);
            z-index: 10;
            transition: transform 600ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
            @media(max-width: 575px){
                bottom: 30px;
                left: 30px;
                right: 30px;
            }


            .type{
                font-style: italic;
                font-weight: 300;
                font-size: 15px;
                color: #fff;
                display: block;
                margin-bottom: 5px;

                @media(max-width: 991px){
                    font-size: 14px;
                }

            }
            h3{
                color: #fff;
                color: #fff;
                font-size: 30px;
                font-weight: 900;
                margin: 0 auto;
                display: block;
                transform: translateY(0px);

                transition: .3s all cubic-bezier(.19,1,.22,1);
                @media(max-width: 1199px){
                    font-size: 25px;
                }
                @media(max-width: 991px){
                    font-size: 20px;
                }
                @media(max-width: 575px){
                    font-size: 20px;
                }
            }
            .project-details{
                font-size: 20px;
                font-weight: 400;
                line-height: 1.2;;
                color: #fff;
                position: absolute;
                left: 0;
                top: 100%;
                opacity: 0;
                margin-top: 5px;
                transform: translateY(30%);
                transition: color 400ms cubic-bezier(0.7, 0, 0.3, 1), transform 400ms cubic-bezier(0.7, 0, 0.3, 1) 0ms, opacity 300ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
                @media(max-width: 1199px){
                    font-size: 18px;
                }
                @media(max-width: 767px){
                    font-size: 16px;
                }

            }

        }

        .overlay{
            position:absolute;
            width: 100%;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(130%) skewY(20deg);
            z-index: 8;

            transition: color 400ms cubic-bezier(0.7, 0, 0.3, 1), transform 400ms cubic-bezier(0.7, 0, 0.3, 1) 0ms, opacity 300ms cubic-bezier(0.7, 0, 0.3, 1) 0ms
        }

        &:hover, &:focus{
            .overlay{
                opacity:1;
                visibility: visible;
                transform: translateY(0%) skewY(0deg);
                transition: transform 500ms cubic-bezier(0.7, 0, 0.3, 1) 0ms, opacity 300ms cubic-bezier(0.7, 0, 0.3, 1) 100ms;
            }

            .bg{
                transform: translateY(-10%);
                transition: transform 500ms cubic-bezier(0.7, 0, 0.3, 1) 100ms;
            }

            .inner{

                transform: matrix(1, 0, 0, 1, 0, -90);


                h3{
                    text-shadow: none;
                }

                .project-details{
                    transform: translateY(0%);
                    opacity: 1;
                    transition: transform 500ms cubic-bezier(0.7, 0, 0.3, 1) 0ms, opacity 300ms cubic-bezier(0.7, 0, 0.3, 1) 100ms;
                }

            }

            &:before{

                transform: translate(100%, 100%);
            }

            .mobile-title{
                span{
                    background: darken($pink, 20%);
                }
            }
        }

    }

}

.skew-row{
    margin: 0;

    margin-top: 80px;
    position: relative;
    z-index: 100;
    .col-xl-3{
        padding: 0;
        .skew{
            background-size: cover;
            flex-basis: 100%;
            max-width: 100%;
            font-size: 14px;
            display: flex;
                align-items: center;
                justify-content: center;
            .skew-content{
                padding: 100px 40px;
                text-align: center;
                @media(max-width:575px) {
                   padding: 80px 20px;
                }

                h4{
                    font-size: 30px;
                    color: #fff;
                    font-weight: 700;
                    @media(max-width: 575px){
                        font-size: 25px;
                    }
                }
                p{
                    font-size: 18px;
                    color: #fff;
                    font-weight: 100;
                    line-height: 1.4;
                    @media(max-width: 575px){
                        font-size: 16px;
                        font-weight: 300;
                    }
                }
                .button{
                    margin-top: 5px;
                    margin-bottom: 0;
                    @include bounce-to-top(#fff, $brown, darken($pink, 10%), #fff)
                }
            }

            &.skew-first{
                background-image: linear-gradient(270deg, rgba(137,170,0,0.8),rgba(137,170,0,0.8)), url("../../img/content-area1.jpg");
            }
            &.skew-second{
                background-image: linear-gradient(270deg, rgba(241,109,88,0.8), rgba(241,109,88,0.8)), url("../../img/content-area1.jpg");
            }
            &.skew-third{
                background-image: linear-gradient(270deg, rgba(255,186,78,0.8), rgba(255,186,78,0.8)), url("../../img/content-area1.jpg");
            }
            &.skew-fourth{
                background-image: linear-gradient(270deg, rgba(105,65,57,0.8), rgba(105,65,57,0.8)), url("../../img/content-area1.jpg");
            }

            &.skew-second, &.skew-fourth{
                -webkit-transform: translateZ(0)  skewY(15deg);
                -moz-transform: translateZ(0)  skewY(15deg);
                -o-transform: translateZ(0)  skewY(15deg);
                transform: translateZ(0)  skewY(15deg);
                .skew-content{
                    -webkit-transform: translateZ(0)  skewY(-15deg) !important;
                    -moz-transform: translateZ(0)  skewY(-15deg) !important;
                    -o-transform: translateZ(0)  skewY(-15deg) !important;
                    transform: translateZ(0)  skewY(-15deg) !important;
                }
                @media(max-width: 767px){
                    -webkit-transform: translateZ(0)  skewY(5deg);
                    -moz-transform: translateZ(0)  skewY(5deg);
                    -o-transform: translateZ(0)  skewY(5deg);
                    transform:translateZ(0)   skewY(5deg);
                    .skew-content{
                        -webkit-transform:translateZ(0)   skewY(-5deg) !important;
                        -moz-transform: translateZ(0)  skewY(-5deg) !important;
                        -o-transform: translateZ(0)  skewY(-5deg) !important;
                        transform: translateZ(0)  skewY(-5deg) !important;
                    }
                }
                @media(max-width: 575px){
                    -webkit-transform: translateZ(0)  skewY(-5deg);
                    -moz-transform: translateZ(0)  skewY(-5deg);
                    -o-transform: translateZ(0)  skewY(-5deg);
                    transform:translateZ(0)   skewY(-5deg);
                    .skew-content{
                        -webkit-transform:translateZ(0)   skewY(5deg) !important;
                        -moz-transform: translateZ(0)  skewY(5deg) !important;
                        -o-transform: translateZ(0)  skewY(5deg) !important;
                        transform: translateZ(0)  skewY(5deg) !important;
                    }
                }
            }
            &.skew-first, &.skew-third{
                -webkit-transform: translateZ(0)  skewY(-15deg);
                -moz-transform: translateZ(0)  skewY(-15deg);
                -o-transform: translateZ(0)  skewY(-15deg);
                transform: translateZ(0)  skewY(-15deg);
                .skew-content{
                    -webkit-transform: translateZ(0)  skewY(15deg) !important;
                    -moz-transform: translateZ(0)  skewY(15deg) !important;
                    -o-transform: translateZ(0)  skewY(15deg) !important;
                    transform:translateZ(0)   skewY(15deg) !important;
                }

                @media(max-width: 767px){
                    -webkit-transform: translateZ(0)  skewY(-5deg);
                    -moz-transform: translateZ(0)  skewY(-5deg);
                    -o-transform: translateZ(0)  skewY(-5deg);
                    transform: translateZ(0)  skewY(-5deg);
                    .skew-content{
                        -webkit-transform: translateZ(0)  skewY(5deg) !important;
                        -moz-transform: translateZ(0)  skewY(5deg) !important;
                        -o-transform: translateZ(0)  skewY(5deg) !important;
                        transform: translateZ(0)  skewY(5deg) !important;
                    }
                }


            }
        }

    }
}
.more-projects{
    margin-top: 100px;
    h2{
        font-size: 36px;
        font-weight: 100;
        color: #fff;
        margin-bottom: 40px;
        @media(max-width: 1199px){
            font-size: 33px;
        }
        @media(max-width: 991px){
            font-size: 30px;
        }
        @media(max-width: 767px){
            font-size: 28px;
        }
    }

}

.mfp-move-horizontal {

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s;

    transform: translateX(-50px);
  }

  &.mfp-bg {
    opacity: 0;
	  transition: all 0.3s;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: translateX(0);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: translateX(50px);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }

  }

}


.project-slider{
    padding: 0 100px;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 2000px;
    @media(max-width:1800px) {
        padding: 0 50px;
    }
    @media(max-width:767px) {
        padding: 0;
    }
    .item{
        background-size: cover;
        background-position: 50% 50%;
        height: 30vw;

        @media(max-width:1199px) {
            height: 60vw;
        }


        .overlay{
            background: rgba(0,0,0,0.3);
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 10;
            opacity: 0;
            visibility: hidden;
            transform: scale(1);
            -webkit-transform: scale(1);
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{

                flex-basis: 15%;
                max-width: 15%;
                height: auto;
                path{
                    fill: rgba(255,255,255,1)
                }

            }

            @media(max-width:575px) {
                display: none!important;
            }
        }

        &:hover{
            .overlay{
                opacity: 1;
                visibility: visible;
                transform: scale(0.9);
                -webkit-transform: scale(0.9);
            }

        }
    }

    .owl-nav{
        @media(max-width:575px) {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
        }
    }

    .owl-prev, .owl-next{
        position: absolute;
        top: calc(50% - 25px);
        background: none;
        padding: 0;
        margin: 0;
        background: #1A181E;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
        @media(max-width:575px) {
            position: relative;
            top: auto;
            margin: 0 5px;
            width: 40px;
            height: 40px;
        }
        i{
            color: #fff;
            font-size: 25px;
        }

        &:hover{
            background: $pink;
        }

    }

    .owl-prev{


        left: 20px;
        @media(max-width:575px) {
            left: auto;
        }
    }

    .owl-next{

        right: 20px;
        @media(max-width:575px) {
            right: auto;
        }
    }

}
