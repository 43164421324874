body{
    &.about-us{
        .top-header{
            @media(max-width: 575px){
                margin-bottom: -50px;
                z-index: 100;
            }
        }
        #footer #testimonials{
            padding: 50px 30% 30px;
            margin-top: 0;
            @media(max-width: 1199px){
                padding: 50px 10% 30px;
                margin-top: 0;
            }
           @media(max-width: 991px){
                padding: 50px 100px 30px;
                margin-top: 0;
            }
            @media(max-width: 767px){
                padding: 50px 20px 30px;
                margin-top: 0px;
            }
            @media(max-width: 575px){
                padding: 30px 0 30px;
                margin-top: 0px;
            }
            
        }
    }
    
    &.home{
        #why.tiles{
            @media(max-width: 575px){
                    padding-top: 30px;
                    padding-bottom: 50px;
                    margin-top: 0;
            }
        }
        
        #footer #testimonials{
            padding: 50px 30% 30px;
            margin-top: 0;
            @media(max-width: 1199px){
                padding: 50px 10% 30px;
                margin-top: 0;
            }
           @media(max-width: 991px){
                padding: 50px 100px 30px;
                margin-top: 0;
            }
            @media(max-width: 767px){
                padding: 50px 20px 30px;
                margin-top: 0px;
            }
            @media(max-width: 575px){
                padding: 30px 0 30px;
                margin-top: 0px;
            }
            
        }
    }
    &.web-development{
        #process{
            @media(max-width: 575px){
                    padding-top: 30px!important;
                    padding-bottom: 50px;
                    margin-top: 0!important;
            }
        }
        
    }
}