$font-stack : 'Museo Sans',
Arial,
sans-serif;
$font-stack2 :Georgia,
Cambria,
"Hoefler Text",
"Liberation Serif",
Times,
"Times New Roman",
serif;
$main-bg: #2d2c32;
$main-bg-image: url('../../img/bg-new-mobile.png');
$nav-bg: #3c3137;
$pink-selected: #ef6e58;
$pink: #ef6e58;
$pink-hover: #a44839;
$brown: #694139;

$green: #89aa00;

.font-light {
    font-weight: 100;
}

@font-face {
    font-family: 'Museo Sans';
    src: url('font/MuseoSans-700.eot');
    src: url('font/MuseoSans-700.eot?#iefix') format('embedded-opentype'),
        url('font/MuseoSans-700.woff') format('woff'),
        url('font/MuseoSans-700.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans';
    src: url('font/MuseoSans-500.eot');
    src: url('font/MuseoSans-500.eot?#iefix') format('embedded-opentype'),
        url('font/MuseoSans-500.woff') format('woff'),
        url('font/MuseoSans-500.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans';
    src: url('font/MuseoSans-300.eot');
    src: url('font/MuseoSans-300.eot?#iefix') format('embedded-opentype'),
        url('font/MuseoSans-300.woff') format('woff'),
        url('font/MuseoSans-300.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans';
    src: url('font/MuseoSans-100.eot');
    src: url('font/MuseoSans-100.eot?#iefix') format('embedded-opentype'),
        url('font/MuseoSans-100.woff') format('woff'),
        url('font/MuseoSans-100.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

a,
span {
    text-decoration: none;
    transition: .3s all cubic-bezier(.19,1,.22,1);
}

body {
    background-image: $main-bg-image;
    background-size: auto;
    background-repeat: repeat;
    border-radius: 0px;
    background-attachment: fixed;

    font-family: $font-stack;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.3;

    @media(max-width: 767px) {
        font-size: 15px;
    }

    .wrapper {

        .overlay {
            //opacity: 0;
            //visibility: hidden;
            transform: translateX(-100%);
            transition: 0.3s all 0.1s ease;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            //background: #962613;
            z-index: 1000;

            .toggle {
                position: absolute;
                cursor: pointer;
                //opacity: 0;
                //border-bottom-right-radius: 50px;
                //border-top-right-radius: 50px;
                //visibility: hidden;
                transform: translateX(-70px);
                transition: 0s transform cubic-bezier(0.075, 0.82, 0.165, 1) 0s, 0.3s background ease 0;
                text-decoration: none;
                width: 320px;
                padding-left: 250px;
                height: 100%;
                margin: 0;
                background: #d04c36;
                left: 0;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);

                svg {
                    height: 30px;
                    width: 30px;

                    path {
                        fill: #fff;
                    }
                }

                &:focus,
                &:hover {
                    background: darken(#d04c36, 5%);
                }
            }
        }

    }

    &.menu-open {
        overflow: hidden;

        #mobile-menu {

            transform: translateX(0%);

            li {

                transform: translateX(0);
                opacity: 1;

                &:nth-child(1) {
                    transition-delay: 0.1s;
                }

                &:nth-child(2) {
                    transition-delay: 0.25s;
                }

                &:nth-child(3) {
                    transition-delay: 0.4s;
                }

                &:nth-child(4) {
                    transition-delay: 0.55s;
                }

                &:nth-child(5) {
                    transition-delay: 0.7s;
                }

                &:nth-child(6) {
                    transition-delay: 0.85s;
                }
            }
        }

        .wrapper {

            .overlay {

                opacity: 1;
                visibility: visible;
                transform: translateX(0%);

                .toggle {
                    transition: 0.5s transform cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s, 0.3s background ease 0;
                    transform: translateX(0);
                }
            }

        }


    }
}

canvas {
    z-index: 10;
}

nav {
    background: #3c3137;
    color: white;
    margin-bottom: 0px !important;
}

h1 {
    color: white;
    font-weight: 300;
    font-size: 30px
}
