/* main css file */

@mixin bounce-to-top($from, $from_color, $to, $to_color) {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    transition: .3s all cubic-bezier(.19, 1, .22, 1);

    overflow: hidden;
    background: $from;
    background: linear-gradient(to bottom, $from 50%, $to 50%);
    background-repeat: repeat;
    background-size: 100% 200%;
    color: $from_color;

    &:hover,
    &:focus,
    &:active {
        color: $to_color !important;
        background-size: 200% 100%;
        background-position: 0% 100% !important;
        background-repeat: repeat;
        background-size: 100% 200%;


    }
}

@import 'base';
@import 'header';
@import 'slider';
@import 'canvas';
@import 'home';
@import 'portfolio';
@import 'page';
@import 'footer';
@import 'body_class_css';  
@import 'simplelightbox';
@import 'cross_browser';
body {
    img {
        max-width: 100%;
    }

    .button {
        border-radius: 30px;
        padding: 12px 20px;
        font-weight: 500;
        margin: 30px auto 20px;
        display: inline-block;
        letter-spacing: 1px;
        cursor: pointer;

        @media(max-width:575px) {
            padding: 10px 18px;
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }

        i {
            margin-left: 5px;
        }

        @include bounce-to-top($pink, #fff, darken($pink, 20%), #fff)
    }

    a {
        color: $pink;
        transition: .3s all cubic-bezier(.19, 1, .22, 1);

        &:hover,
        &:focus {
            color: darken($pink, 10%);
            text-decoration: none;
        }
    }

    .cont {
        color: red;


    }

    @mixin cf {
        &:after {
            content: "";
            display: table;
            clear: both;
        }
    }

    $gridUnit: 100vw / 15.1;
    $small: $gridUnit * 4;
    $large: $gridUnit * 7;

    

    .grid-wrapper {
        @include cf;
        position: relative;
        height: $gridUnit * 9;
        overflow: hidden;
        margin-bottom: -12vw;
        z-index: 100;
        
        @media(max-width: 1199px){
            height: $gridUnit * 12;
            margin-bottom: -20vw;
            flex-wrap: wrap;
        }

        @media(max-width:991px){
            height: $gridUnit * 14;
            margin-bottom: -20vw;
            flex-wrap: wrap;
        }
        
    }

    .grid-item {
        position: absolute;
        -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        clip-path: url("#clip-diamond-demo");
        background-size: cover;
        background-position: 50% 50%;
        user-select: none;
      
        
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            transition: .3s all cubic-bezier(.19, 1, .22, 1);
        }
    }

    .diamond-small {
        
        height: $small;
        width: $small;
        @media(max-width: 1199px){
            height: $small * 1.25;
            width: $small * 1.25;
        }
        @media(max-width: 991px){
            height: $small * 1.75;
            width: $small * 1.75;
        }
        .diamond__content {
            height: $small;
            width: $small;
            @media(max-width: 1199px){
                height: $small * 1.25;
                width: $small * 1.25;
            }
            @media(max-width: 991px){
                height: $small *1.75;
                width: $small * 1.75;
            }
        }
    }

    

    .diamond__content {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;
        .inner{
            padding: 100px;
            text-align: center;
            @media(max-width: 1400px){
                padding: 60px;
            }
            h4{
                font-size: 30px;
                font-weight: bold;
                color: #fff;
                transition: .3s all cubic-bezier(.19, 1, .22, 1);
                
                @media(max-width: 1400px){
                    font-size: 25px;
                }
            }
            p{
                font-size: 18px;
                font-weight: 100;
                color: #fff;
                padding: 0 30px;
                transition: .3s all cubic-bezier(.19, 1, .22, 1);
                @media(max-width: 1400px){
                    font-size: 16px;
                }
                @media(max-width: 991px){
                    padding: 0 50px;
                }
                @media(max-width: 767px){
                    padding: 0 20px;
                }
            }
        }

        &:hover{
            .inner{
                h4{
                    transform: translateY(-5px);
                }
                p{
                    transform: translateY(5px);
                }
            }
        }
        
    }

    .layout-1 {
        .diamond-extra-left {
            background-image: url(../../img/bg.jpg);
            background-size: auto;
            background-repeat: repeat;
            border-radius: 0px;
            background-attachment: fixed;
            height: $large*1.5;
            width: $large*1.5;
            left: - $gridUnit * 6.5;
            top: -$gridUnit *0.75;
           
            @media(max-width: 1199px){
                top: $gridUnit * 0.25;
                left: - $gridUnit *5.5;
            }
           
            
        }
        .diamond-extra-right {
            background-image: url(../../img/bg.jpg);
            background-size: auto;
            background-repeat: repeat;
            border-radius: 0px;
            background-attachment: fixed;
            height: $large*1.5;
            width: $large*1.5;
            left:  $gridUnit * 11;
            top: -$gridUnit *0.75;
        
            @media(max-width: 1199px){
                top: $gridUnit * 0.25;
                left:  $gridUnit * 10;
            }
        }
        
        .diamond-1 {
            top: $gridUnit * 0.5;
            left: $gridUnit * 2;
            @media(max-width: 1199px){
                top: 0;
            }
            @media(max-width: 991px){
                left: 0;
            }
            background-image: url('/img/content-area1-600.jpg');
            &:before{
                background: rgba(255,186,44,0.8); 
            }

            &:hover{
                &:before{
                    background: rgba(255,186,44,1); 
                }
            }
        }

        .diamond-2 {
            top: $gridUnit * 4.5;
            left: $gridUnit * 2;
            @media(max-width: 1199px){
                top: $gridUnit * 6;
                left: $gridUnit * 2;
            }
            @media(max-width: 991px){
                left: 0;
            }
            background-image: url('/img/value.jpg');

            &:before{
                background:rgba(34,188,201,0.8);
            }
            &:hover{
                &:before{
                    background:rgba(34,188,201,1);
                }
            }
        }

        .diamond-4 {
            top: $gridUnit * 4.5;
            left: $gridUnit * 9;
            background-image: url('/img/content-area4-600.jpg');
            @media(max-width: 1199px){
                top: $gridUnit * 6;
                left: $gridUnit * 8;
            }
            @media(max-width: 991px){
                left: $gridUnit * 7.75;
            }
            &:before{
                background:rgba(240,108,87, 0.8);
            }
            &:hover{
                &:before{
                    background:rgba(240,108,87,1);
                }
            }
            
        }

        .diamond-5 {
            top: $gridUnit * 0.5;
            left: $gridUnit * 9;
            @media(max-width: 1199px){
                top: 0;
                left: $gridUnit * 8;
            }
            @media(max-width: 991px){
                left: $gridUnit * 7.75;
            }
            background-image: url('/img/value425.jpg');

            &:before{
                background:rgba(137, 170, 0, 0.8);
            }
            &:hover{
                &:before{
                    background:rgba(137, 170, 0,1);
                }
            }
        }

        .diamond-large {
            height: $large;
            width: $large;
            top: $gridUnit * 1;
            left: $gridUnit * 4;
            @media(max-width: 1199px){
                top: $gridUnit *2;
            }
            @media(max-width: 991px){
                top: $gridUnit *3;
            }
            background-image: url('/img/content-area5-600.jpg');

            &:before{
                background:rgba(105,65,57,0.8);
            }
            &:hover{
                &:before{
                    background:rgba(105,65,57,1);
                }
            }
            .diamond__content {
                height: $large;
                width: $large;
               
                    .inner{
                        
                        h4{
                            font-size: 35px;
                           
                            @media(max-width: 1199px){
                                font-size: 30px;
                            }
                            @media(max-width: 991px){
                                font-size: 25px;
                            }
                            
                        }
                        p{
                            font-size: 20px;
                            
                            @media(max-width: 1199px){
                                font-size: 18px;
                            }

                            @media(max-width: 991px){
                                font-size: 16px;
                            }
                            
                        }
                    }
            
            }
        }
    }
    @media(max-width: 767px){
       
    .grid-wrapper {
        display: flex;
        align-items: stretch;
        justify-content: center;
        height: auto!important;
        overflow: initial;
        width: 100%;
        margin-bottom: 0;
        margin-top: 50px;

        .grid-item {
            -webkit-clip-path: none;
            clip-path: none;
            clip-path: none;
            flex-basis: 100%;
            max-width: 100%;
            position: relative;
            left: auto!important;
            right: auto!important;
            bottom: auto!important;
            top: auto!important;
            width: auto!important;
            height: auto!important;
            .diamond__content {
                width: auto!important;
                height: auto!important;
            }
            
        }
        
    }
}

@media(max-width: 575px){
    .grid-wrapper {
        margin-top: 100px;
    }
}

}