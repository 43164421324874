.home-slider{
    position: relative;
    z-index: 100;
    @media(max-width: 575px){
        margin-bottom: -50px;
        z-index: 100;
    }
    .item{
        text-align: center;


        h3{
            font-size: 46px;
            font-weight: 100;
            color: #fff;
            @media(max-width: 1199px){
                font-size: 40px;
            }
            @media(max-width: 991px){
                font-size: 35px;
            }
            @media(max-width: 767px){
                font-size: 30px;
            }
            @media(max-width: 575px){
                font-size: 25px;
            }
        }
        .subtitle{
            font-size: 20px;
            font-weight: 100;
            color: #fff;
            @media(max-width: 1199px){
                font-size: 19px;
            }
            @media(max-width: 991px){
                font-size: 18px;
            }
            @media(max-width: 767px){
                font-size: 17px;
            }
            @media(max-width: 575px){
                font-size: 16px;
            }
        }
        >div{
            width: 100%;
            text-align: center;
        }
        .inner{
            padding: 50px 20%;
            @media(max-width: 1199px){
                padding: 50px 10%
            }
            @media(max-width: 991px){
                padding: 40px 50px;
            }
            @media(max-width: 767px){
                padding: 30px 10px;
            }
            @media(max-width: 575px){
                padding: 20px 0;
            }
        }
    }

    .owl-prev, .owl-next{
        position: absolute;
        top: calc(50% - 25px);
        background: none;
        padding: 0;
        margin: 0;
        background: #1A181E;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        @media(max-width: 575px){
            top: auto;
        }
        i{
            color: #fff;
            font-size: 25px;
        }

        &:hover{
            background: $pink;
        }

    }

    .owl-prev{


        left: 20px;
    }

    .owl-next{

        right: 20px;
    }

    .owl-dots{
        margin-top: 0!important;
        margin-bottom: 0;
        position: absolute;
        bottom: 0;

        margin: 0 auto;
        display: block;
        left: 0;
        right: 0;
        .owl-dot{
            span{
                margin: 0 4px;
                width:10px;
                height: 10px;
                background: #7d3f34;
                box-shadow: 0 0 5px rgba(0,0,0,0.6);
            }

            &:hover, &:focus{
                span{
                    background: $pink;
                }

            }

            &.active{
                span{
                    background: $pink;
                }  
            }
        }
    }

}