.page {

    margin-bottom: -140px;

    .main-text {
        padding-bottom: 50px;
        color: $brown;
    }

    .tiles {
        background-image: url(../../img/tiles.png);
        background-color: #f2f3f3;

        background-size: auto;
        background-position: 50% 50%;
        color: #666;

        .top-header {
            h2 {
                color: $brown;
            }

            .text {
                color: $brown;
            }
        }
    }


    #why {
        .each2 {
            margin-bottom: 40px;

            @media(max-width: 991px) {
                margin-bottom: 0;
            }
        }
    }

    .services {
        max-width: 2000px;
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        margin: 0 -15px;
        margin-bottom: 150px;
       
        @media(max-width:767px) {
            padding: 0;
        }

        .service {
            padding: 40px;
            border-radius: 0;
            position: relative;
            flex-basis:  50%;
            max-width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            overflow: hidden;
            .overlay{
                position:absolute; 
            width: 100%;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        
                background: rgba(255,255,255,0.1);
                transform: translateY(100%) skewY(0deg);
                z-index: 1;
                transition: transform 200ms cubic-bezier(0.7, 0, 0.3, 1) 0ms, opacity 300ms cubic-bezier(0.7, 0, 0.3, 1) 0ms
            }
            .inner{ 
                position: relative;
                z-index: 2;
            }
            &:before{
                content: '';
                display: block;
                padding-bottom: 20%;
                
            }
            @media(max-width:1199px) {
                flex-basis: 50%;
                max-width:  50%;
            }
            @media(max-width:991px) {
                flex-basis: 50%;
                max-width: 50%;
                &:before{
                    content: '';
                    display: block;
                    padding-bottom: 10%;
                    
                }
            }

            @media(max-width:575px) {
                flex-basis: 100%;
                max-width: 100%;
               
            }
           

            &:hover{
                    .overlay{
                        opacity:1;
                        visibility: visible;
                        transform: translateY(0%) skewY(0deg);
                        transition: transform 500ms cubic-bezier(0.7, 0, 0.3, 1) 0ms, opacity 300ms cubic-bezier(0.7, 0, 0.3, 1) 100ms;
                    } 
                
            }

            h2 {
                position: relative;
                z-index: 2;
                color: #fff;
                font-size: 28px;
                margin-bottom: 10px;
                font-weight: 700;
                transition: all 0.3s 0s ease;

                @media(max-width: 1199px) {
                    font-size: 25px;
                }

                @media(max-width: 991px) {
                    font-size: 20px;
                }

                @media(max-width: 767px) {
                    font-size: 18px;
                }
                @media(max-width: 575px) {
                    font-size: 15px;
                }

            }

            p{
                line-height: 1.6;
                margin: 20px 0;
            }

            .button {
                margin: 0;
            }

        }
    }

    &.contact-page {


        .info {
            font-size: 20px;
            padding-right: 30px;
            flex-basis: 100%;
            max-width: 100%;
            border-right: 1px solid $pink;

            h3 {
                color: $pink;
            }
        }

        #contact-form {
            flex-basis: 100%;
            max-width: 100%;

            .form-control {
                border-radius: 0px;
                height: auto;
                font-size: 16px;
                padding: 10px 20px;

                &:hover,
                &:focus {

                    box-shadow: none;
                    outline: none;

                }

                &:focus {

                    border: 1px solid $brown;

                }
            }

            .button {
                margin-top: 5px;
            }

            .error{
                font-style: italic;
                margin-bottom: 20px;
                color: #fff;
            }

            .success{
                h3{
                    color: #fff;
    font-weight: 100;
    font-size: 20px;
                }
            }
        }


        .map {
            margin-top: 40px;
        }


    }

}